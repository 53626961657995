<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12">
      <div class="flex items-center mb-4">
        <div class="is-size-4">{{ $route.name }}</div>
        <div class="flex-grow"></div>
        <div class="flex items-center" v-if="adminsOnly()">
          <b-dropdown
            aria-role="list"
            position="is-bottom-left"
            :close-on-click="false"
          >
            <button class="button" slot="trigger" slot-scope="{ active }">
              <span>General Settings</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
            </button>

            <b-dropdown-item aria-role="listitem" custom class="">
              <div class="heading">Front End</div>
              <div class="my-2">
                <small class=""
                  >Last activity: <br /><strong>{{ cachedDate }}</strong></small
                >
                <b-button
                  v-if="userHasPermission('write-products')"
                  class="my-2"
                  @click="startCaching"
                  :loading="caching"
                  >Regenerate Cache</b-button
                >
                <p class="p-2 has-background-light" v-if="cachingMessage">
                  <small>{{ cachingMessage }}</small>
                </p>
              </div>
            </b-dropdown-item>

            <b-dropdown-item aria-role="listitem" custom class="">
              <div class="heading">Others</div>
              <div class="my-2">
                <small>coming soon</small>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div></div>
      </div>
      <!-- <VueApexCharts width="380" type="donut" :options="options" :series="series"/> -->
      <!-- <VueApexCharts
          type="line"
          height="350"
          :options="chartOptions"
          :series="series"
        /> -->
      i need everyone's concerned MKTA employee opinion on what metrics or
      useful data we need to display here
      <br />
      we can use charts or any other data visualization
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import store from "@/store";
export default {
  title: () => {
    return `Admin - Dashboard`;
  },
  components: {
    Sidebar
    // VueApexCharts
  },
  data: () => ({
    stages: [],
    options: {},
    // series: [44, 55, 41, 17, 15],
    series: [
      {
        name: "Likes",
        data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5]
      }
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "line"
      },
      stroke: {
        width: 7,
        curve: "smooth"
      },
      xaxis: {
        type: "datetime",
        categories: [
          "1/11/2000",
          "2/11/2000",
          "3/11/2000",
          "4/11/2000",
          "5/11/2000",
          "6/11/2000",
          "7/11/2000",
          "8/11/2000",
          "9/11/2000",
          "10/11/2000",
          "11/11/2000",
          "12/11/2000",
          "1/11/2001",
          "2/11/2001",
          "3/11/2001",
          "4/11/2001",
          "5/11/2001",
          "6/11/2001"
        ],
        tickAmount: 10,
        labels: {
          formatter: function(value, timestamp, opts) {
            return opts.dateFormatter(new Date(timestamp), "dd MMM");
          }
        }
      },
      title: {
        text: "Social Media",
        align: "left",
        style: {
          fontSize: "16px",
          color: "#666"
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#FDD835"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        }
      },
      markers: {
        size: 4,
        colors: ["#FFA41B"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7
        }
      },
      yaxis: {
        min: -10,
        max: 40,
        title: {
          text: "Engagement"
        }
      }
    },
    caching: false,
    cachingMessage: ""
  }),
  computed: {
    cachedDate() {
      return this.$store.state.dashboard.last_cache_activity;
    }
  },
  watch: {},
  methods: {
    userHasPermission(data) {
      return store.getters.userHasPermission(data);
    },
    adminsOnly() {
      return (
        store.getters.userRole == "super-admin" ||
        store.getters.userRole == "admin"
      );
    },
    startCaching() {
      this.caching = true;
      store
        .dispatch("regenerateCache")
        .then(res => {
          this.cachingMessage = res.data.message;
        })
        .finally(() => {
          this.caching = false;
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch).then(() => {
        next();
      });
    }
  }
};
</script>
